import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setUserLoginPassword } from '../../actions/login';
import TextField from '../../components/TextField';

class PasswordTextField extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            showPassword: false,
        };
        this.onChange = this.onChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }

    componentDidMount () {
        const password = localStorage.getItem('passwordASDDashboard');

        if (password) {
            this.props.onChange(password);
        }
    }

    onChange (value) {
        this.props.onChange(value);
    }

    handleClickShowPassword () {
        this.setState((state) => ({ showPassword: !state.showPassword }));
    }

    render () {
        return (
            <TextField
                handleClickShowPassword={this.handleClickShowPassword}
                id="password_text_field"
                name="password"
                showPassword={this.state.showPassword}
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.props.value}
                variant="filled"
                onChange={this.onChange}/>
        );
    }
}

PasswordTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.login.password,
    };
};

const actionsToProps = {
    onChange: setUserLoginPassword,
};

export default connect(stateToProps, actionsToProps)(PasswordTextField);
