import { ADD_ENTRY_URL, ENTRIES_FETCH_URL, ENTRY_URL } from '../constants/url';
import Axios from 'axios';
import {
    ADD_ENTRY_DIALOG_HIDE,
    ADD_ENTRY_DIALOG_SHOW,
    ADD_ENTRY_ERROR,
    ADD_ENTRY_IN_PROGRESS,
    ADD_ENTRY_SUCCESS,
    DELETE_ENTRY_DIALOG_HIDE,
    DELETE_ENTRY_DIALOG_SHOW,
    DELETE_ENTRY_ERROR,
    DELETE_ENTRY_IN_PROGRESS,
    DELETE_ENTRY_SUCCESS,
    ENTRIES_FETCH_ERROR,
    ENTRIES_FETCH_IN_PROGRESS,
    ENTRIES_FETCH_SUCCESS,
    ENTRY_DENY_SET,
    ENTRY_TICKET_ID_SET,
} from '../constants/entries';

const fetchEntriesInProgress = () => {
    return {
        type: ENTRIES_FETCH_IN_PROGRESS,
    };
};

const fetchEntriesSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: ENTRIES_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchEntriesError = (message) => {
    return {
        type: ENTRIES_FETCH_ERROR,
        message,
    };
};

export const fetchEntries = (eventId, ID, skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchEntriesInProgress());

    const url = ENTRIES_FETCH_URL(eventId, ID, skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_ASDDashboard'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchEntriesSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchEntriesError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const showEntryDialog = (value) => {
    return {
        type: ADD_ENTRY_DIALOG_SHOW,
        value,
    };
};

export const hideEntryDialog = () => {
    return {
        type: ADD_ENTRY_DIALOG_HIDE,
    };
};

export const setEntryTicketId = (value) => {
    return {
        type: ENTRY_TICKET_ID_SET,
        value,
    };
};

export const setEntryDeny = (value) => {
    return {
        type: ENTRY_DENY_SET,
        value,
    };
};

const addEntryInProgress = () => {
    return {
        type: ADD_ENTRY_IN_PROGRESS,
    };
};

const addEntrySuccess = (value) => {
    return {
        type: ADD_ENTRY_SUCCESS,
        value,
        message: 'Gated access added successfully',
    };
};

const addEntryError = (message) => {
    return {
        type: ADD_ENTRY_ERROR,
        message,
        variant: 'error',
    };
};

export const addEntry = (eventId, ID, data, cb) => (dispatch) => {
    dispatch(addEntryInProgress());

    const url = ADD_ENTRY_URL(eventId, ID);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_ASDDashboard'),
        },
    })
        .then((res) => {
            dispatch(addEntrySuccess(res.data && res.data.result));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(addEntryError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const deleteEntryInProgress = () => {
    return {
        type: DELETE_ENTRY_IN_PROGRESS,
    };
};

const deleteEntrySuccess = (value) => {
    return {
        type: DELETE_ENTRY_SUCCESS,
        value,
        message: 'Gated access deleted successfully',
    };
};

const deleteEntryError = (message) => {
    return {
        type: DELETE_ENTRY_ERROR,
        message,
        variant: 'error',
    };
};

export const deleteEntry = (eventId, gatedAccessId, id, cb) => (dispatch) => {
    dispatch(deleteEntryInProgress());

    const url = ENTRY_URL(eventId, gatedAccessId, id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_ASDDashboard'),
        },
    })
        .then((res) => {
            dispatch(deleteEntrySuccess(res.data && res.data.success));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(deleteEntryError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

export const showDeleteEntryDialog = (value) => {
    return {
        type: DELETE_ENTRY_DIALOG_SHOW,
        value,
    };
};

export const hideDeleteEntryDialog = () => {
    return {
        type: DELETE_ENTRY_DIALOG_HIDE,
    };
};
